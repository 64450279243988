import moment from "moment";
import copy from 'copy-to-clipboard';
import { onAuthStateChanged, User } from "firebase/auth";

import {
  BASE_URL, PRODUCTS_PATH, COMPLETED_SETUP, CONNECTED, DAYS, EMPTY_STRING, EVERYONE, HOME_PATH,
  LOGIN_PATH, PROFILE_PATH, SENT_PATH, SHARE_INFO, SLASH, TO_GIFT_ID, SUCCESS, EARN_PATH, WISHLIST,
  LIKES, TAGS_LIST, INBOX, INBOX_PATH, T, SHARE_INFO_FREQUENCY
} from "../constants";
import BadgeService from "./badgeService";
import ConnectionService from "./connectionService";
import NotificationService from "./notifcationService";
import OfferService from "./offerService";
import ProductService from "./productService";
import ReactionService from "./reactionService";
import StorageService from "./storageService";
import UserService from "./userService";
import WishlistService from "./wishlistService";
import CongratsService from "./congratsService";
import { auth } from "../firebase";
import RoomService from "./roomService";

export const goToHomeOnClick = () => {
  window.location.assign(HOME_PATH);
};

export const goToInbox = (roomId?: string) => {
  window.location.assign(INBOX_PATH);
};

export const handleSentOffersClick = () => {
  window.location.assign(SENT_PATH);
  window.scrollTo(0, 0);
};

// export const updateWishlistsInProduct = (productObj: Product, user: Account, removed: boolean): Product => {
//   if (removed) {
//     return {
//       ...productObj,
//       usersWishlisted: productObj?.usersWishlisted?.filter(subUser => subUser.id !== user?.id)
//     };
//   };
//   for (const subUser of productObj?.usersWishlisted) {
//     if (subUser?.id === user?.id) {
//       return;
//     }
//   };
//   return {
//     ...productObj,
//     usersWishlisted: [ ...productObj?.usersWishlisted, {
//       id: user?.id,
//       username: user?.username,
//       name: user?.name,
//       image: UserService.getImageOrEmpty(user?.images),
//       phone: user?.phone,
//       email: user?.email
//     }]
//   };
// }

export const trimText = (text: string, maxLen: number) : string => {
  if(text?.length > maxLen) {
    text = text.substring(0, maxLen).trim() + "...";
  }
  return text;
}

export const saveReactionProductUser = async (currentUser: Account, newUserReaction: string, productObj: Product) => {
  if (!currentUser || currentUser?.username?.trim() === EMPTY_STRING) {
    return;
  };
  await ReactionService.upsertReaction({
    status: newUserReaction,
    user: {
      id: currentUser?.id,
      username: currentUser?.username,
      name: currentUser?.name,
      image: StorageService.getImageOrEmpty(currentUser?.images),
      phone: currentUser?.phone,
      email: currentUser?.email
    },
    product: {
      id: productObj?.id,
      title: productObj?.title,
      url: productObj?.url,
      description: productObj?.description,
      shop: productObj?.shop,
      images: productObj?.images,
      price: productObj?.price,
      tags: productObj?.tags
    },
    createdAt: Date.now(),
    updatedAt: Date.now()
  });
}

export const saveWishlistProductUser = async (currentUser: Account, productObj: Product, removed: boolean, wishlistItem?: WishlistItem) => {
  if (currentUser?.username?.trim() === EMPTY_STRING) {
    return;
  };
  if (removed) {
    await WishlistService.deleteWishlist(wishlistItem?.id);
    return;
  };
  await WishlistService.addWishlist({
    user: {
      id: currentUser.id,
      username: currentUser.username,
      name: currentUser.name,
      image: StorageService.getImageOrEmpty(currentUser?.images),
      phone: currentUser.phone,
      email: currentUser.email
    },
    product: {
      id: productObj?.id,
      title: productObj?.title,
      url: productObj?.url,
      description: productObj?.description,
      shop: productObj?.shop,
      images: productObj?.images,
      price: productObj?.price,
      tags: productObj?.tags
    },
    createdAt: Date.now(),
    updatedAt: Date.now()
  });
}

export const noUserReationOrWishlist = (currentUserId: string, liked: SubUser[], skipped: SubUser[], usersWishlisted: SubUser[]) : boolean => {
  const userArray: SubUser[] = liked.concat(skipped).concat(usersWishlisted);
  if (userArray?.length === 0) {
    return true;
  }
  for (const user of userArray) {
    if (user?.id === currentUserId) {
      return false;
    }
  }
  return true;
}

export const hasNoConnection = (userConnections: Connection[], lookupUserId: string) : boolean => {
  for (let i = 0; i < userConnections?.length; i++) {
    const connection: Connection = userConnections[i];
    if (lookupUserId === connection?.receiver?.id || lookupUserId === connection?.requester?.id) {
      return false;
    }
  }
  return true;
}

export const sendToProfile = () => {
  const dateOfSetup: string = localStorage.getItem(COMPLETED_SETUP);
  if (!dateOfSetup || (moment(Date.now()).diff(moment(parseInt(dateOfSetup)), DAYS) > SHARE_INFO_FREQUENCY)) {
    localStorage.setItem(COMPLETED_SETUP, Date.now().toString());
    window.location.assign(PROFILE_PATH);
  }
}

export const displayShareInfoModal = (username: string, setUserLink: (val: string) => void, setShowShareInfoModal: (val: boolean) => void) : boolean => {
  const dateOfInfoModal: string = localStorage.getItem(SHARE_INFO);
  if (!dateOfInfoModal || (moment(Date.now()).diff(moment(parseInt(dateOfInfoModal)), DAYS) > SHARE_INFO_FREQUENCY)) {
    localStorage.setItem(SHARE_INFO, Date.now().toString());
    setUserLink(BASE_URL + SLASH + username);
    setShowShareInfoModal(true);
    return true;
  };
  return false;
}

export const shuffleObjArray = <Type> (objArray: Type[]): Type[] => {
  for (let i = objArray?.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [objArray[i], objArray[j]] = [objArray[j], objArray[i]];
  }
  return objArray;
}

export const getMax25RandomAccounts = (accountsArray: Account[]): Account[] => {
  const totalUsers: number = accountsArray?.length;
  if (totalUsers < 25) {
    return shuffleObjArray(accountsArray);
  }
  const randomIndices = [];
  for (let i = 0; i < 25; i++) {
    let newRandom = Math.floor(Math.random() * totalUsers - 1);
    while (randomIndices.includes(newRandom)) {
      newRandom = Math.floor(Math.random() * totalUsers - 1);
    };
    randomIndices.push(newRandom);
  }
  const suggestedAccounts = [];
  for (const i of randomIndices) {
    suggestedAccounts.push(accountsArray.at(i));
  }
  return shuffleObjArray(suggestedAccounts);
}

export const authenticateUser = async (currentUser: Account, signOut: () => Promise<void>) => {
  if (!currentUser || currentUser?.username?.trim() === EMPTY_STRING) {
    await signOut();
    window.location.assign(LOGIN_PATH);
    return;
  };
  return true;
}

export const isVisible = <Type extends FeedItem> (feedObj: Type, connectionStatus: string) : boolean => {
  if (feedObj?.scope === EVERYONE || connectionStatus === CONNECTED) {
    return true;
  }
  return false;
}

export const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

export const trimPrice = (price: string) : string => {
  if (price === undefined) {
    return
  }
  if (price.slice(-2) === ".0") {
    return price.trim() + "0";
  }
  return price;
}

export const addMoneyAmounts = (amountA: string, amountB: string) => {
  const total: string = (parseFloat(amountA) + parseFloat(amountB)).toFixed(2);
  return trimPrice(total);
}

export const removeNotification = async (currentUserId: string, notifcation: string) => {
  await NotificationService.removeNotification(currentUserId, notifcation);
};

export const getBadgePoints = (amount: string, shippingFee: string, processingFee: string) : number => {
  return BadgeService.getPointsIncrease(
    parseFloat(ProductService.getTotalPrice(amount, shippingFee, processingFee))
  );
};

export const updateImageOnError = async (offer: Offer) : Promise<void> => {
  const updatedGifter = await UserService.getAccountById(offer.gifter.id);
  const updatedGiftee = await UserService.getAccountById(offer.giftee.id);
  offer.gifter.image = StorageService.getImageOrDefault(updatedGifter?.images);
  offer.giftee.image = StorageService.getImageOrDefault(updatedGiftee?.images);
  await OfferService.updateOffer({
    ...offer,
    gifter: {
      ...offer.gifter,
      username: updatedGifter?.username,
      name: updatedGifter?.name,
      image: StorageService.getImageOrEmpty(updatedGifter?.images),
      phone: updatedGifter?.phone
    },
    giftee: {
      ...offer.giftee,
      username: updatedGiftee?.username,
      name: updatedGiftee?.name,
      image: StorageService.getImageOrEmpty(updatedGiftee?.images),
      phone: updatedGiftee?.phone
    }
  });
};

export const handleCopyClick = (
  textToCopy: string,
  setAlertIsOpen: (val: boolean) => void,
  setAlertType: (val: string) => void,
  setAlertMessage: (val: string) => void) => {
  copy(textToCopy);
  setAlertIsOpen(true);
  setAlertType(SUCCESS);
  setAlertMessage("Copied username!");
};

export const goToEarnSetup = () => {
  window.location.assign(EARN_PATH);
};

export const getRandomInList = <T> (objArray: T[]) => {
  if (objArray.length === 0) {
    return;
  };
  return objArray?.at(Math.floor(Math.random() * objArray?.length));
};

export const sendGiftHelper = async (currentUser: Account, giftee: SubUser | Account, signOut: () => Promise<void>) => {
  await authenticateUser(currentUser, signOut);
  if (ConnectionService.canGift(currentUser, giftee)) {
    localStorage.setItem(TO_GIFT_ID, giftee?.id);
    const wishlist: WishlistItem[] = await WishlistService.getWishlistsByUserId(giftee?.id);
    if (wishlist?.length > 0) {
      window.location.assign(`/${giftee?.username}/${WISHLIST}`);
      return;
    };
    const likes: Reaction[] = await ReactionService.getReactionsByUserId(giftee?.id);
    if (likes?.length > 0) {
      window.location.assign(`/${giftee?.username}/${LIKES}`);
      return;
    };
  };
  window.location.assign(PRODUCTS_PATH);
};

export const checkIfIdInArray = (celeIdsCongratulated: string[], celebrationId: string) : boolean => {
  return celeIdsCongratulated && celeIdsCongratulated?.length > 0 && celeIdsCongratulated?.includes(celebrationId);
};

export const getCongratsFromUser = async (currentUserId: string, setCeleIdsCongratulated: (val: string[]) => void) => {
  const allCongrats: Congrats[] = await CongratsService.getAllUserCongrats(currentUserId);
  if (allCongrats) {
    setCeleIdsCongratulated(allCongrats?.filter(congratsObj => congratsObj?.isActive)
    .map(congratsObj => congratsObj?.celebration?.id));
  };
};

export const getCongratsNotifs = async (accountId: string) : Promise<string[] | null> => {
  const allNotifs: Notifications = await NotificationService?.getNotifications(accountId);
  const congratsNotifs: string[] = allNotifs?.congratsReceived;
  if (congratsNotifs && congratsNotifs?.length > 0) {
    return congratsNotifs;
  };
};

export const updateCurrentUser = async (currentUserId: string, setCurrentUserToStorage: (cu: Account) => void) : Promise<void> => {
  const cuser: Account = await UserService.getAccountById(currentUserId);
  setCurrentUserToStorage(cuser);
};

export const addOrDeleteTagHelper = async (index: number, isAdding: boolean, tagsOnArray: boolean[], setLoading: (val: boolean) => void,
    setTagsOnArray: (tags: boolean[]) => void, setProducts: (prods: Product[]) => void) => {
  if (isAdding && tagsOnArray?.at(index)) {
    return;
  };
  setLoading(true);
  if (isAdding) {
     tagsOnArray[index] = true;
  } else {
     tagsOnArray[index] = false;
  };
  const newTagsOnArray: boolean[] = [ ...tagsOnArray ];
  setTagsOnArray(newTagsOnArray);
  const tagsToShow: string[] = TAGS_LIST.filter((tag, index) => newTagsOnArray.at(index));
  let productsWithTags: Product[];
  if (tagsToShow?.length > 0) {
     productsWithTags = await ProductService.getProductsBasedOnTags(tagsToShow);
  } else {
     productsWithTags = await ProductService.getProducts();
  };
  setProducts(productsWithTags);
  setLoading(false);
};

export const resetTagsHelper = async (setLoading: (val: boolean) => void, setTagsOnArray: (tags: boolean[]) => void, setProducts: (prods: Product[]) => void) => {
  setLoading(true);
  const allProducts: Product[] = await ProductService.getProducts();
  setProducts(allProducts);
  setTagsOnArray(new Array(TAGS_LIST?.length).fill(false));
  setLoading(false);
};

export const inChatRoom = (path: string) => {
  const urlParts: string[] = path?.split(SLASH);
  if (urlParts?.includes(INBOX) && urlParts?.includes(T)) {
    return true;
  };
  return false;
};

export const setUp = (currentUser: Account, signOut: () => Promise<void>) => {
  onAuthStateChanged(auth, (loggedInUser: User) => {
    if (!loggedInUser || !currentUser || currentUser?.username?.trim() === EMPTY_STRING) {
      signOut();
      window.location.assign(LOGIN_PATH);
      return;
    };
  });
};

export const getSubUserObj = (acc: Account) : SubUser => {
  return {
    id: acc?.id,
    username: acc?.username,
    name: acc?.name,
    image: StorageService.getImageOrEmpty(acc?.images),
    phone: acc?.phone,
    email: acc?.email
  };
};

export const isSmallScreen = (width: number) => {
  if (width > 768) {
    return false;
  };
  return true;
};

export const checkIfCanMessage = async (senderId: string, receiverId: string) : Promise<boolean> => {
  const conn: Connection = await ConnectionService.getLatestConnectionBetweenUsers(senderId, receiverId);
  if (conn && conn?.status === CONNECTED) {
    return true;
  };
  return await OfferService.offerMadeFromAToB(senderId, receiverId);
};

export const getCorrespondent = (currentUserId: string, membersObjs: SubUser[]) : SubUser => {
  return membersObjs?.find(member => member?.id !== currentUserId);
};

export const messageHelper = async (currentUser: Account, receiver: Account, setShowMsgInfoModal: (val: boolean) => void) => {
  const canMessage: boolean = await checkIfCanMessage(currentUser?.id, receiver?.id);
  if (canMessage) {
    const roomObj: Room = await RoomService.getOrCreateRoom([getSubUserObj(currentUser), getSubUserObj(receiver)]);
    if (roomObj) {
      window.location.assign(INBOX_PATH + SLASH + T + SLASH + roomObj?.id);
      return;
    };
  };
  setShowMsgInfoModal(true);
};

export const getTotalNotifsForAllRooms = (totalNotifsForRooms: {[key: string]: number} ) => {
  let totalNotif: number = 0;
  if (totalNotifsForRooms) {
    for (const roomIdNotifArray of Object.entries(totalNotifsForRooms)) {
      totalNotif += roomIdNotifArray[1];
    };
  };
  return totalNotif;
};
