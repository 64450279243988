import { EMPTY_STRING, EVERYONE, UNCONFIRMED } from "../constants"

export const emptyAddress: Address = {
  address1: EMPTY_STRING,
  address2: EMPTY_STRING,
  town: EMPTY_STRING,
  province: EMPTY_STRING,
  postcode: EMPTY_STRING,
  country: EMPTY_STRING
}

export const emptyCheckout: Checkout = {
  paymentId: EMPTY_STRING,
  shippingAddress: {
    firstName: EMPTY_STRING,
    lastName: EMPTY_STRING,
    ...emptyAddress,
  },
  shippingFee: EMPTY_STRING,
  processingFee: EMPTY_STRING,
}

export const defaultOfferScope: OfferScope = {
  gifter: UNCONFIRMED,
  giftee: UNCONFIRMED
}

export const emptyOffer: Offer = {
  id: EMPTY_STRING,
  gifter: null,
  giftee: null,
  message: EMPTY_STRING,
  status: EMPTY_STRING,
  product: null,
  scope: defaultOfferScope,
  checkout: emptyCheckout,
  createdAt: 0,
  updatedAt: 0
}

export const emptyAccount: Account = {
  id: EMPTY_STRING,
  username: EMPTY_STRING,
  name: EMPTY_STRING,
  email: EMPTY_STRING,
  bio: EMPTY_STRING,
  address: emptyAddress,
  phone: EMPTY_STRING,
  images: [],
  createdAt: 0,
  updatedAt: 0
}

export const emptyCelebration: Celebration = {
  id: EMPTY_STRING,
  type: EMPTY_STRING,
  author: null,
  description: EMPTY_STRING,
  scope: EVERYONE,
  images: [],
  selectedProducts: [],
  createdAt: 0,
  updatedAt: 0
}

export const emptyPrice: Price = {
  amount: EMPTY_STRING,
  shippingFee: EMPTY_STRING,
  currencyCode: EMPTY_STRING
}

export const emptyPrivacy: Privacy = {
  likes: EVERYONE,
  wishlist: EVERYONE
}

export const emptyAccountSettings: AccountSettings = {
  id: EMPTY_STRING,
  accountId: EMPTY_STRING,
  privacyScopes: emptyPrivacy
}

export const emptyBadge: Badge = {
  id: EMPTY_STRING,
  account: null,
  points: 0,
  updatedAt: 0
}

export const emptyCongrats: Congrats = {
  id: EMPTY_STRING,
  sender: null,
  celebration: null,
  isActive: true,
  createdAt: 0,
  updatedAt: 0
}
