import { FC, useState, useContext, useEffect } from 'react';
import styled from '@emotion/styled';
import { CircularProgress, Grid, Skeleton } from '@mui/material';
import { useParams } from 'react-router-dom';
import { onAuthStateChanged } from "firebase/auth";
import { Modal } from 'react-bootstrap';
import { EmojiEventsOutlined } from '@mui/icons-material';
import confetti from 'canvas-confetti';

import AccountForm from '../children/AccountForm';
import ProfileModal from '../children/ProfileModal';
import Alert from '../children/Alert';
import {
  ButtonsGroup, ModalBodyPadding20, ConnectionsIconButton, Item600, NoWidthButton, ProfileImg190x190Right,
  SubText, ProductImgGiftsList, DetailsGiftsList, TextGiftsList, IconSize16, BadgeIcon, Badge,
  LowPriorityButton
} from '../children/styled';
import { AuthContext } from '../../providers/AuthProvider';
import UserService from '../../services/userService';
import {
  BLOCK, CELEBRATIONS, CONNECTED, DEFAULT_PROFILE_IMG, EMPTY_STRING,
  ERROR, EVERYONE, LIKED, LIKES, LOGIN_PATH, NONE, PENDING, PROFILE_IMAGES_FOLDER, SKIPPED, SLASH, TO_GIFT_ID, WISHLIST
} from '../../constants';
import NotificationService from '../../services/notifcationService';
import { auth } from '../../firebase';
import ConnectionService from '../../services/connectionService';
import ReactionService from '../../services/reactionService';
import GiftingForm from './GiftingForm';
import {
  authenticateUser, checkIfIdInArray, displayShareInfoModal, getCongratsFromUser, getCongratsNotifs,
  handleCopyClick, sendGiftHelper, saveReactionProductUser, messageHelper
} from '../../services/helpers';
import WishlistBlack from "../../images/wishlist-black.png";
import WishlistGray from "../../images/wishlist-gray.png";
import HeartBlack from "../../images/heart-black.png";
import HeartGray from "../../images/heart-gray.png";
import WishlistService from '../../services/wishlistService';
import Celebration from '../children/Celebration';
import StorageService from '../../services/storageService';
import CelebrationService from '../../services/celebrationService';
import CelebrationFormModal from '../children/CelebrationFormModal';
import BadgeService from '../../services/badgeService';
import PromoModal from '../children/PromoModal';
import BadgeModal from '../children/BadgeModal';
import ImageModal from '../children/ImageModal';
import DeleteModal from '../children/DeleteModal';
import CongratsModal from '../children/CongratsModal';
import MsgInfoModal from '../children/MsgInfoModal';

const Main = styled(Grid)`
  margin-top: 50px;
  margin-bottom: 130px;
`

const LoadingImg = styled(Skeleton)`
  padding: 7px;
  float: right;
`

const LoadingDivs = styled(Skeleton)`
  margin-bottom: 8px;
`

const Name = styled.h3`
  text-transform: capitalize;
  margin-right: 8px;
`

const Handle = styled.p`
  color: rgb(142, 142, 142);
  font-size: 18px;
  line-height: 20px;
  margin-bottom: 15px;
  margin-top: 0px;
  text-align: left;
`

const ProfileDetails = styled(Grid)`
  margin-left: 85px;
  margin-top: 0px !important;
`

const NameAndButtons = styled(Grid)`
  margin-top: 35px;
`

const Icon = styled.i`
  margin-right: 7px;
  font-size: 15px;
`

const ProductsDiv = styled.div`
  padding: 1.25rem 0.5rem;
  margin-top: 30px;
  max-width: 600px;
  width: 100%;
`

const Hr = styled.hr`
  margin-top: 60px;
  margin-bottom: 0px;
  max-width: 600px;
  width: 100%;
`

const Container = styled.div`
  padding: 10px;
  margin: 0 auto;
  margin-top: 70px;
  max-width: 100%;
`

const TabDiv = styled.div`
  max-width: 600px;
  width: 100%;
`

const Center = styled(Grid)`
  max-width: 400px;
  width: 100%;
  margin: 0px auto 0px auto;
`

const Tab = styled.div`
  cursor: pointer;
  line-height: 23.8px;
  font-size: 14px;
  text-align: center;
  color: rgb(115, 115, 115);
  font-weight: 500;
  padding-top: 8px;
`

const ActiveWishlist = styled(Tab)`
  width: 90px;
`

const NonActiveWishlist = styled(Tab)`
  width: 75px;
`

const LikesTab = styled(Tab)`
  width: 85px;
`

const CelebrationsTab = styled(Tab)`
  width: 115px;
`

const ActiveText = styled.div`
  margin: 0px;
  color: #000000;
`

const ActiveHr = styled.hr`
  margin: 0px auto 0px auto;
  color: transparent;
  border-top: 1px solid #000000;
  position: relative;
  bottom: 35px;
  width: 90px;
  opacity: 90%;
`

const IconImage = styled.img`
  width: 11px;
  margin-right: 3px;
  margin-bottom: 3px;
`

const TrophyIcon = styled(EmojiEventsOutlined)`
  margin-right: 4px;
  margin-bottom: 1px;
  font-size: 18px;
`

interface AccountProps {
  isNewAccount: boolean;
  tab?: string;
};
const Account: FC<AccountProps> = ({ isNewAccount, tab }) => {
  const [account, setAccount] = useState<Account | null>();
  const [images, setImages] = useState<string[]>([]);
  const [selectedImage, setSelectedImage] = useState<string>(DEFAULT_PROFILE_IMG);
  const [imageToModal, setImageToModal] = useState<string>(EMPTY_STRING);
  const [displayCSS, setDisplayCSS] = useState<string>(NONE);
  const [displayImageModalCSS, setDisplayImageModalCSS] = useState<string>(NONE);
  const [editing, setEditing] = useState<boolean>(false);
  const [personal, setPersonal] = useState<boolean>(false);
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [requested, setRequested] = useState<boolean>(false);
  const [isRequesting, setIsRequesting] = useState<boolean>(false);
  const [alertIsOpen, setAlertIsOpen] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<string>(EMPTY_STRING);
  const [alertMessage, setAlertMessage] = useState<string>(EMPTY_STRING);
  const [confirmDeleteModal, setConfirmDeleteModal] = useState<boolean>(false);
  const [imageIndexToDelete, setImageIndexToDelete] = useState<number | null>();
  const [likedProducts, setLikedProducts] = useState<Product[]>([]);
  const [wishlistedItems, setWishlistedItems] = useState<WishlistItem[]>([]);
  const [accCelebrations, setAccCelebrations] = useState<Celebration[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<Product | null>();
  const [giftingFormOpen, setGiftingFormOpen] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<string>(CELEBRATIONS);
  const [showShareInfoModal, setShowShareInfoModal] = useState<boolean>(false);
  const [userLink, setUserLink] = useState<string>(EMPTY_STRING);
  const [celebrationModalOpen, setCelebrationModalOpen] = useState<boolean>(false);
  const [celebrationToEdit, setCelebrationToEdit] = useState<Celebration | null>();
  const [celebrationEdited, setCelebrationEdited] = useState<Celebration | null>(null);
  const [fetching, setFetching] = useState<boolean>(false);
  const [showProductsToSelect, setShowProductsToSelect] = useState<boolean>(false);
  const [accountLoading, setAccountLoading] = useState<boolean>(false);
  const [accBadge, setAccBadge] = useState<Badge>();
  const [showBadgeModal, setShowBadgeModal] = useState<boolean>(false);
  const [celebrationToDelete, setCelebrationToDelete] = useState<Celebration>(null);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [celeIdsCongratulated, setCeleIdsCongratulated] = useState<string[]>(null);
  const [celebrationToShowCongrats, setCelebrationToShowCongrats] = useState<Celebration>(null);
  const [congratsModalOpen, setCongratsModalOpen] = useState<boolean>(false);
  const [accCeleIdsWithNotifs, setAccCeleIdsWithNotifs] = useState<string[]>(null);
  const [showMsgInfoModal, setShowMsgInfoModal] = useState<boolean>(false);

  const { username } = useParams();
  const { currentUser, signOut } = useContext(AuthContext);

  const handleEditClick = () => {
    window.scrollTo(0, 0);
    setEditing(true);
  }

  const handleImgClick = () => {
    if (images?.length > 0) {
      setDisplayCSS(BLOCK);
    } else {
      setEditing(true);
    }
  }

  const handleConnectClick = async () => {
    await authenticateUser(currentUser, signOut);
    await ConnectionService.addConnectionReq(currentUser, account);
    setRequested(true);
  }

  const handleRemoveClick = async () => {
    await ConnectionService.updateConnectionRemoved(account?.id, currentUser?.id);
    setIsRequesting(false);
  }

  const handleConfirmClick = async () => {
    await ConnectionService.updateConnectionAccepted(account?.id, currentUser?.id);
    setIsConnected(true);
  }

  const handleAnonymousConnectClick = () => {
    window.location.assign(LOGIN_PATH);
  }

  const handleCancelClick = () => {
    setImageIndexToDelete(null);
    setConfirmDeleteModal(false);
  }

  const handleGiftIconClick = async (product: Product) => {
    await authenticateUser(currentUser, signOut);
    if (ConnectionService.canGift(currentUser, account)) {
      localStorage.setItem(TO_GIFT_ID, account?.id);
    };
    setSelectedProduct(product);
    setGiftingFormOpen(true);
  };

  const handleConfirmDeleteClick = async () => {
    const updatedImages: string[] = [ ...images ];
    const deletedImageUrl: string = updatedImages?.splice(imageIndexToDelete, 1)[0];
    await UserService.updateAccount({
      ...currentUser,
      images: updatedImages,
      updatedAt: Date.now()
    });
    if (deletedImageUrl && deletedImageUrl !== EMPTY_STRING) {
      await StorageService.deleteImage(StorageService.getFileNameFromURL(deletedImageUrl), PROFILE_IMAGES_FOLDER);
    };
    setImages(updatedImages);
    setSelectedImage(updatedImages?.at(0));
    setConfirmDeleteModal(false);
  };

  const handleBadgeClick = () => {
    confetti();
    setShowBadgeModal(true);
	};

  const handleTrashClick = async (tabClicked: string, product: Product) => {
    if (tabClicked === LIKES) {
      setLikedProducts(likedProducts?.filter(productObj => productObj?.id !== product?.id));
      await saveReactionProductUser(currentUser, SKIPPED, product);
      return;
    };
    const newWishlistItems: WishlistItem[] = [];
    for (const wishlistItem of wishlistedItems) {
      if (wishlistItem?.product?.id !== product?.id) {
        newWishlistItems?.push(wishlistItem);
      } else {
        await WishlistService?.deleteWishlist(wishlistItem?.id);
      };
    };
    setWishlistedItems(newWishlistItems);
  };

  const handleCelebrationProductsChange = (updatedCelebration: Celebration) => {
    const newCelebrations: Celebration[] = accCelebrations?.map(celebrationObj => celebrationObj?.id === updatedCelebration?.id ?
      { ...celebrationObj, ...updatedCelebration } : celebrationObj);
    setAccCelebrations(newCelebrations);
  };

  const handleMessageClick = async () => {
    const userIsAuthenticated: boolean = await authenticateUser(currentUser, signOut);
    if (userIsAuthenticated) {
      await messageHelper(currentUser, account, setShowMsgInfoModal);
    };
  };

  const confirmDeleteClick = async () => {
    setShowDeleteModal(false);
    setFetching(true);
    setAccCelebrations(accCelebrations?.filter(celebration => celebration?.id !== celebrationToDelete?.id));
    await CelebrationService.deleteCelebrationAndImage(celebrationToDelete);
    setFetching(false);
  };

  const fetchAccount = async (username: string) : Promise<Account> => {
    const accountDetails = await UserService.getAccountByUsername(username);
    if (accountDetails) {
      return accountDetails;
    };
    setAlertIsOpen(true);
    setAlertType(ERROR);
    setAlertMessage("Sorry, this page isn't available. The link you followed may be broken, or the page may have been removed.");
  };

  const getLikedProducts = async (accountId: string) => {
    setFetching(true);
    try {
      const reactions: Reaction[] = await ReactionService.getReactionsByUserId(accountId);
      if (reactions) {
        const productsList: Product[] = [];
        reactions?.forEach(reaction => {
          if (reaction?.status === LIKED) {
            productsList?.push(reaction?.product);
          };
        });
        setLikedProducts(productsList);
      };
    } catch (err) {
      console.error("Error loading liked products: ", err)
    };
    setFetching(false);
  };

  const getWishlistedItems = async (accountId: string) => {
    setFetching(true);
    try {
      const wishlistItems: WishlistItem[] = await WishlistService.getWishlistsByUserId(accountId);
      if (wishlistItems) {
        setWishlistedItems(wishlistItems);
      };
    } catch (err) {
      console.error("Error loading wishlist items: ", err)
    };
    setFetching(false);
  };

  const getCelebrations = async (accountId: string, connected: boolean, isPersonal: boolean) => {
    setFetching(true);
    const userCelebrations: Celebration[] = await CelebrationService.getUserCelebrations(accountId);
    if (userCelebrations) {
      if (isPersonal) {
        const celeIdsThatHaveNotifs: string[] = await getCongratsNotifs(accountId);
        if (celeIdsThatHaveNotifs) {
          setAccCeleIdsWithNotifs(celeIdsThatHaveNotifs);
          const idsMap = new Map<string, Celebration>();
          const existingCelebrationIds = [];
          userCelebrations?.forEach(celeObj => {
            existingCelebrationIds.push(celeObj?.id);
            idsMap?.set(celeObj?.id, celeObj);
          });
          const orderedCeleList: Celebration[] = [];
          for (const celeId of celeIdsThatHaveNotifs) {
            if (existingCelebrationIds.includes(celeId)) {
              orderedCeleList?.push(idsMap?.get(celeId));
            } else {
              /**
               * Todo: If celebration is deleted but notif list still contains celebration ID,
               *       then delete in bulk instead of individually calling API below
               */
              await NotificationService.removeCongratsNotification(currentUser?.id, celeId);
            };
          };
          setAccCelebrations([...(orderedCeleList?.concat(
            userCelebrations?.filter(celeObj => !celeIdsThatHaveNotifs?.includes(celeObj?.id))
          ))]);
        } else {
          setAccCelebrations(userCelebrations);
        };
      } else {
        if (connected) {
          setAccCelebrations(userCelebrations);
        } else {
          setAccCelebrations(userCelebrations?.filter(cele => cele?.scope === EVERYONE));
        };
        await getCongratsFromUser(currentUser?.id, setCeleIdsCongratulated);
      };
    };
    setFetching(false);
  };

  const changeUrlParam = (tab: string, currentUrl: string, username: string) : void => {
    const pathArray: string[] = currentUrl?.split(SLASH);
    if (tab === CELEBRATIONS && pathArray?.length === 3) {
      window.history.pushState(null, EMPTY_STRING, SLASH + username);
    } else if (tab === WISHLIST) {
      if (pathArray?.length === 3) {
        window.history.pushState(null, EMPTY_STRING, WISHLIST);
      } else if (pathArray?.length === 2) {
        window.history.pushState(null, EMPTY_STRING, username + SLASH + WISHLIST);
      }
    } else if (tab === LIKES) {
      if (pathArray?.length === 3) {
        window.history.pushState(null, EMPTY_STRING, LIKES);
      } else if (pathArray?.length === 2) {
        window.history.pushState(null, EMPTY_STRING, username + SLASH + LIKES);
      }
    }
  };

  const updateAccImageOnError = async () => {
    let newImages = account?.images;
    if (newImages?.length > 0 && newImages?.at(0) !== EMPTY_STRING) {
      newImages?.shift();
      newImages = [ ...newImages ];
    } else {
      newImages = [EMPTY_STRING];
    };
    setImages(newImages);
    setSelectedImage(newImages?.at(0));
    const updatedAccount: Account = {
      ...account,
      images: newImages
    };
    setAccount(updatedAccount);
    await UserService.updateAccount(updatedAccount);
  };

  const setupImages = (accountImages: string[]) : void => {
    if (accountImages?.length > 0) {
      setImages(accountImages);
      setSelectedImage(accountImages?.at(0));
    };
  };

  const setUp = async () => {
    onAuthStateChanged(auth, (loggedInUser) => {
      if (!loggedInUser) {
        signOut();
      }
    });
    if (isNewAccount) {
      setEditing(true);
      setPersonal(true);
      return;
    };
    if (username === "create") {
      return;
    };
    setAccountLoading(true);
    let isPersonal: boolean = false;
    let connected: boolean = false;
    const accountObj: Account = await fetchAccount(username);
    if (!accountObj) {
      return;
    };
    setAccount(accountObj);
    setupImages(accountObj?.images);
    const badgeObj: Badge = await BadgeService.getUserBadge(accountObj?.id);
    setAccBadge(badgeObj);
    if (currentUser?.id === accountObj?.id) {
      setPersonal(true);
      isPersonal = true;
    } else if (currentUser) {
      const connection = await ConnectionService.getLatestConnectionBetweenUsers(currentUser?.id, accountObj?.id);
      if (connection && connection?.status === PENDING && currentUser?.id === connection?.requester?.id) {
        setRequested(true);
      } else if (connection && connection?.status === PENDING && currentUser?.id === connection?.receiver?.id) {
        setIsRequesting(true);
      } else if (connection && connection?.status === CONNECTED) {
        setIsConnected(true);
        connected = true;
      };
    };
    setAccountLoading(false);
    getCelebrations(accountObj?.id, connected, isPersonal);
    getWishlistedItems(accountObj?.id);
    getLikedProducts(accountObj?.id);
  };

  useEffect(() => {
    setUp();
    if (currentUser) {
      displayShareInfoModal(currentUser?.username, setUserLink, setShowShareInfoModal);
    };

    if (tab && tab === WISHLIST) {
      setActiveTab(WISHLIST);
    } else if (tab && tab === LIKES) {
      setActiveTab(LIKES);
    };
  }, []);

  useEffect(() => {
    if (!account) {
      return;
    };
    const currentUrl: string = window.location.pathname;
    const accountUsername: string = account?.username;
    if (activeTab === CELEBRATIONS) {
      getCelebrations(account?.id, isConnected, personal);
      changeUrlParam(CELEBRATIONS, currentUrl, accountUsername);
    } else if (activeTab === WISHLIST) {
      getWishlistedItems(account?.id);
      changeUrlParam(WISHLIST, currentUrl, accountUsername);
    } else if (activeTab === LIKES) {
      getLikedProducts(account?.id);
      changeUrlParam(LIKES, currentUrl, accountUsername);
    }
  }, [activeTab, account]);

  useEffect(() => {
    if (celebrationEdited) {
      setAccCelebrations(accCelebrations?.map(cele => cele?.id === celebrationEdited?.id ? { ...cele, ...celebrationEdited } : cele)
      );
    };
  }, [celebrationEdited]);

  const getProductItem = (entity: string, product: Product) => {
    return (
      <Item600 container direction="row" justifyContent="flex-start" alignItems="center" key={product?.id}
        className='center-width-24rem' style={{ marginRight: "0px", marginLeft: "0px" }}>
        <ProductImgGiftsList src={product?.images[0]} onClick={() => window.open(product?.url)} />
        <DetailsGiftsList item onClick={() => window.open(product?.url)}>
          <TextGiftsList style={{ fontSize: "15px", marginBottom: "5px" }}>{product?.title}</TextGiftsList>
          <SubText>{product?.shop?.name}</SubText>
          <SubText>£{product?.price?.amount}</SubText>
        </DetailsGiftsList>
        {currentUser && !personal &&
          <ButtonsGroup style={{ marginTop: "0px" }}>
            <ConnectionsIconButton onClick={() => handleGiftIconClick(product)}>
              <IconSize16 className="fas fa-gift" />
            </ConnectionsIconButton>
          </ButtonsGroup>
        }
        {currentUser && personal &&
          <ButtonsGroup style={{ marginTop: "0px" }}>
            <ConnectionsIconButton onClick={() => handleTrashClick(entity, product)}>
              <IconSize16 className="fa-solid fa-trash-can" />
            </ConnectionsIconButton>
          </ButtonsGroup>
        }
      </Item600>
    );
  };

  const getTabs = () => {
    return (
      <>
        {activeTab === CELEBRATIONS &&
          <><CelebrationsTab>
            <ActiveText>
              <TrophyIcon className="fa-solid fa-trophy" style={{ marginBottom: "2px", width: "14px" }} />Celebrations
            </ActiveText>
            <ActiveHr style={{ width: "120px", bottom: "34px" }} />
          </CelebrationsTab>
          <NonActiveWishlist onClick={() => setActiveTab(WISHLIST)} style={{ paddingTop: "7px" }}>
            <IconImage src={WishlistGray} />Wishlist
          </NonActiveWishlist>
          <LikesTab onClick={() => setActiveTab(LIKES)} style={{ marginBottom: "2px" }}>
            <IconImage src={HeartGray} style={{ marginRight: "6px", marginBottom: "2px" }} />Likes
          </LikesTab></>
        }
        {activeTab === WISHLIST &&
          <><CelebrationsTab onClick={() => setActiveTab(CELEBRATIONS)} style={{ paddingTop: "7px" }}>
            <TrophyIcon className="fa-solid fa-trophy" style={{ marginBottom: "2px", width: "14px" }} />Celebrations
          </CelebrationsTab>
          <ActiveWishlist>
            <ActiveText>
              <IconImage src={WishlistBlack} />Wishlist
            </ActiveText>
            <ActiveHr style={{ bottom: "34px" }} />
          </ActiveWishlist>
          <LikesTab onClick={() => setActiveTab(LIKES)} style={{ marginBottom: "2px" }}>
            <IconImage src={HeartGray} style={{ marginRight: "6px", marginBottom: "2px" }} />Likes
          </LikesTab></>
        }
        {activeTab === LIKES &&
          <><CelebrationsTab onClick={() => setActiveTab(CELEBRATIONS)} style={{ marginBottom: "2px" }}>
            <TrophyIcon className="fa-solid fa-trophy" style={{ marginBottom: "2px", width: "14px" }} />Celebrations
          </CelebrationsTab>
          <NonActiveWishlist onClick={() => setActiveTab(WISHLIST)} style={{ marginBottom: "2px" }}>
            <IconImage src={WishlistGray} />Wishlist
          </NonActiveWishlist>
          <LikesTab style={{ marginBottom: "2px" }}>
            <ActiveText>
              <IconImage src={HeartBlack} style={{ marginRight: "6px", marginBottom: "2px" }} />Likes
            </ActiveText>
            <ActiveHr style={{ width: "85px", bottom: "33px" }} />
          </LikesTab></>
        }
      </>
    );
  };

  let buttonsToDisplay: any;
  if (!currentUser) {
      buttonsToDisplay = <>
        <NoWidthButton onClick={handleAnonymousConnectClick} className="single-center-button">
          <Icon className="fas fa-light fa-user-plus" />Connect
        </NoWidthButton>
      </>
  } else if (personal && !editing) {
      buttonsToDisplay =
        <NoWidthButton onClick={handleEditClick} className="single-center-button">
          <Icon className="fas fa-pen" />Edit
        </NoWidthButton>;
  } else if (!personal && !isConnected) {
      let firstButton: any;
      if (isRequesting) {
        firstButton = <>
          <LowPriorityButton onClick={handleRemoveClick}>
            <Icon className="fas fa-light fa-trash-can" />Remove
          </LowPriorityButton>
          <NoWidthButton onClick={handleConfirmClick}><Icon className="fas fa-light fa-check" />Confirm</NoWidthButton>
        </>;
      } else if (requested) {
        firstButton =
          <LowPriorityButton>
            <Icon className="fas fa-light fa-paper-plane" />Requested
          </LowPriorityButton>;
      } else {
        firstButton =
          <NoWidthButton onClick={handleConnectClick}>
            <Icon className="fas fa-light fa-user-plus" />Connect
          </NoWidthButton>;
      };
      buttonsToDisplay = <>
        {firstButton}
        <NoWidthButton onClick={() => sendGiftHelper(currentUser, account, signOut)}>Gift</NoWidthButton>
        <NoWidthButton onClick={handleMessageClick}>Message</NoWidthButton>
      </>
  } else if (!personal && isConnected) {
      buttonsToDisplay = <>
        <LowPriorityButton>
          <Icon className="fas fa-light fa-user-check" />Connected
        </LowPriorityButton>
        <NoWidthButton onClick={() => sendGiftHelper(currentUser, account, signOut)}>Gift</NoWidthButton>
        <NoWidthButton onClick={handleMessageClick}>Message</NoWidthButton>
      </>
  }

  return (<>
    <Container>
      {giftingFormOpen ?
        <GiftingForm productObj={selectedProduct} setGiftingForm={setGiftingFormOpen} />
        :
        <>{personal && editing ?
            <AccountForm setAccount={setAccount} setEditing={setEditing} images={images} setImages={setImages}
              setDisplayCSS={setDisplayCSS} selectedImage={selectedImage} setSelectedImage={setSelectedImage} />
            :
            <Main container justifyContent="center" spacing={0.5}  columns={{ xs: 1, sm: 1, md: 12 }}>
              <Grid item xs={1} md={3}>
                {accountLoading ?
                  <LoadingImg variant="circular" width={190} height={190} className='profile-img' />
                  :
                  <ProfileImg190x190Right id="myImg" src={selectedImage} onError={updateAccImageOnError} onClick={handleImgClick} className="profile-img" />
                }
              </Grid>
              <ProfileDetails item xs={1} md={8} className='profile-details'>
                {accountLoading ?
                  <>
                    <NameAndButtons container direction="row" justifyContent="flex-start" alignItems="center" className='center-header'>
                      <LoadingDivs className='loading-div' style={{ marginBottom: "30px", marginRight: "5px" }} variant="rounded" width={210} height={40} />
                      <LoadingDivs className='loading-div' style={{ marginBottom: "30px" }} variant="rounded" width={210} height={40} />
                    </NameAndButtons>
                    <LoadingDivs className='loading-div-2' variant="rounded" width={180} height={20} />
                    <LoadingDivs variant="rounded" width={180} height={20} />
                  </>
                  :
                  <div className='header-padding'>
                    <NameAndButtons container direction="row" justifyContent="flex-start" alignItems="center" className='center-header'>
                      { account?.name && account?.name !== EMPTY_STRING && <Name className='profile-header-item'>{account?.name}</Name> }
                      { accBadge && accBadge?.points > 0 &&
                        <Badge className='margin-y-900' onClick={handleBadgeClick}>
                          <BadgeIcon className="fa-solid fa-award" /> {accBadge?.points}
                        </Badge>
                      }
                      <Grid item className='profile-header-item'>{buttonsToDisplay}</Grid>
                    </NameAndButtons>
                    <Handle className='handle-center' onClick={() => handleCopyClick(account?.username, setAlertIsOpen, setAlertType, setAlertMessage)}>
                      @{account?.username}
                      <i className="fa-regular fa-copy" style={{ marginLeft: "7px", cursor: "pointer" }} />
                    </Handle>
                    <div className="center-bio">
                      {account?.bio?.split(/\r?\n/)?.map((text, i) => (
                        <TextGiftsList style={{ textAlign: "left" }} key={i}>{text}</TextGiftsList>
                      ))}
                    </div>
                  </div>
                }
                <Hr />
                <TabDiv>
                  <Center container direction="row" justifyContent="space-between" alignItems="center">
                    {getTabs()}
                  </Center>
                </TabDiv>
                <ProductsDiv>
                  { account && !fetching && activeTab === LIKES && likedProducts?.length > 0 && likedProducts?.map(product => getProductItem(LIKES, product)) }
                  { account && !fetching && activeTab === LIKES && likedProducts?.length === 0 && <div className='center'>No Likes Yet</div> }
                  { account && !fetching && activeTab === WISHLIST && wishlistedItems?.length > 0 && wishlistedItems?.map(wishlistItem => getProductItem(WISHLIST, wishlistItem?.product))}
                  { account && !fetching && activeTab === WISHLIST && wishlistedItems?.length === 0 && <div className='center'>No Wishlist Yet</div> }
                  { account && !fetching && activeTab === CELEBRATIONS && accCelebrations?.length > 0 && accCelebrations?.map(celebrationObj =>
                    <Celebration celebration={celebrationObj} key={celebrationObj?.id} setCelebrationToEdit={setCelebrationToEdit} setCelebrationModalOpen={setCelebrationModalOpen}
                      personal={personal} handleGiftIconClick={handleGiftIconClick} handleCelebrationProductsChange={handleCelebrationProductsChange} setImageToModal={setImageToModal}
                      setShowProductsToSelect={setShowProductsToSelect} celebrationEdited={celebrationEdited} setDisplayImageModalCSS={setDisplayImageModalCSS} confetti={confetti}
                      setCelebrationToDelete={setCelebrationToDelete} setShowDeleteModal={setShowDeleteModal} setCelebrationToShowCongrats={setCelebrationToShowCongrats}
                      setCongratsModalOpen={setCongratsModalOpen} congratulated={checkIfIdInArray(celeIdsCongratulated, celebrationObj?.id)}
                      newNotif={accCeleIdsWithNotifs?.includes(celebrationObj?.id)} />
                  )}
                  { account && !fetching && activeTab === CELEBRATIONS && accCelebrations?.length === 0 && <div className='center'>No Celebrations Yet</div> }
                  { (!account || fetching) &&
                    <div style={{ textAlign: "center", marginTop: "10px", marginBottom: "20px" }}>
                      <CircularProgress style={{ color: "#585858" }} />
                    </div>
                  }
                </ProductsDiv>
              </ProfileDetails>
            </Main>
          }
          <ProfileModal displayCSS={displayCSS} selectedImage={selectedImage} images={images} setSelectedImage={setSelectedImage}
            setDisplayCSS={setDisplayCSS} setConfirmDeleteModal={setConfirmDeleteModal} setImageIndexToDelete={setImageIndexToDelete}
            canDelete={currentUser?.id === account?.id} />
          <Modal show={confirmDeleteModal} onHide={() => setConfirmDeleteModal(false)} size="sm" centered>
            <ModalBodyPadding20>
              <Grid container direction="row" justifyContent="center" alignItems="center">
                <NoWidthButton onClick={handleCancelClick} style={{ backgroundColor: "white", border: "solid 1px #E4E6EB", color: "#050505", marginBottom: "0px" }}>Cancel</NoWidthButton>
                <NoWidthButton onClick={handleConfirmDeleteClick} style={{ backgroundColor: "white", border: "solid 1px #E4E6EB", color: "#f44336", marginBottom: "0px" }}>Remove</NoWidthButton>
              </Grid>
            </ModalBodyPadding20>
          </Modal>
        </>}
    </Container>
    {!isNewAccount &&
      <PromoModal showShareInfoModal={showShareInfoModal} setShowShareInfoModal={setShowShareInfoModal} />
    }
    <CelebrationFormModal celebrationToEdit={celebrationToEdit} celebrationModalOpen={celebrationModalOpen} setCelebrationModalOpen={setCelebrationModalOpen}
      showProductsToSelect={showProductsToSelect} setShowProductsToSelect={setShowProductsToSelect} setCelebrationEdited={setCelebrationEdited} />
    <BadgeModal showBadgeModal={showBadgeModal} setShowBadgeModal={setShowBadgeModal} accountId={account?.id} />
    <ImageModal displayCSS={displayImageModalCSS} setDisplayCSS={setDisplayImageModalCSS} selectedImage={imageToModal} />
    <DeleteModal showDeleteModal={showDeleteModal} setShowDeleteModal={setShowDeleteModal} confirmDeleteClick={confirmDeleteClick} />
    <CongratsModal currentUser={currentUser} signOut={signOut} celebrationObj={celebrationToShowCongrats} congratsModalOpen={congratsModalOpen}
      setCongratsModalOpen={setCongratsModalOpen} setAlertIsOpen={setAlertIsOpen} setAlertType={setAlertType} setAlertMessage={setAlertMessage} />
    <MsgInfoModal showMsgInfoModal={showMsgInfoModal} setShowMsgInfoModal={setShowMsgInfoModal} account={account} />
    <Alert alertType={alertType} alertIsOpen={alertIsOpen} alertMessage={alertMessage} setAlertIsOpen={setAlertIsOpen} /></>
  );
};

export default Account;


// const getPrivacy = async (entityType: string) : Promise<boolean> => {
//   if (isConnected || personal) {
//     return true;
//   };
//   const accountSettings: AccountSettings = await AccountSettingsService.getAccountSettings(currentUser?.id);
//   if (!accountSettings) {
//     await AccountSettingsService.addAccountSettings({
//       ...emptyAccountSettings,
//       accountId: currentUser?.id
//     });
//     return true;
//   };
//   const privacyForEntity: string = accountSettings?.privacyScopes[entityType];
//   if (privacyForEntity && privacyForEntity === EVERYONE) {
//     return true;
//   };
//   return false;
// }