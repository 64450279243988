import { useContext, useEffect, useState } from 'react';
import tw from "twin.macro";
import styled from '@emotion/styled';
import { Button as RdButton } from 'react-bootstrap';
import { Grid, Skeleton } from '@mui/material';

import Hero from "../../images/hero.svg";
import Product from "../children/Product";
import { PRODUCTS_PATH, REGISTER_PATH } from '../../constants';
import { FooterContainer, MainDiv, NoWidthButton, ZeroMarginGrid } from '../children/styled';
import { AuthContext } from '../../providers/AuthProvider';
import ProductService from '../../services/productService';
import { goToEarnSetup } from '../../services/helpers';
import Footer from '../children/Footer';

const ColumnsContainer = styled.div`
  position: relative;
  padding-top: 150px;
  padding-bottom: 280px;
`

const TwoColumn = styled.div`
  ${tw`flex flex-col xl:flex-row lg:items-center mx-auto py-2 md:px-6`}
  margin-bottom: 70px;
  max-width: 1440px;
`

const LeftColumn = tw.div`relative lg:w-5/12 text-center max-w-lg mx-auto lg:max-w-none lg:text-left`;
const RightColumn = tw.div`relative mt-12 lg:mt-0 flex-1 flex flex-col justify-center lg:self-end sm:ml-4 xl:ml-40`;
const TextContent = tw.div`text-center md:text-left`;
const HeroImg = styled.img`
  ${tw`w-3/4 md:w-full`}
  display: block;
  margin-left: auto;
  margin-right: auto;
`

// const Steps = tw.ul`mt-4 px-0`;
// const Step = tw.li`mt-6 flex flex-col md:flex-row items-center md:items-start`;
// const StepText = tw.div`mt-3 md:mt-0`;
// const StepDescription = tw.p`mt-1 max-w-xs leading-normal text-sm text-gray-600 font-medium`;

// const StepHeading = styled.h6`
//   ${tw`leading-none text-xl font-semibold`}
//   color: rgb(92, 92, 92) !important
// `

const Heading = styled.h1`
  ${tw`
    text-center md:text-left
    text-3xl md:text-6xl`
  }
  color: rgb(75, 85, 99);
  font-weight: 700;
  padding: 0px 8px;
  margin: 0px;
`

const Heading3 = styled.h3`
  ${tw`
    text-gray-700
    leading-tight`
  }
  color: rgb(92, 92, 92) !important;
  padding: 8px;
  padding-right: 30px;
  font-size: 18px;
  line-height: 1.7;
  margin: 0px;
  font-weight: 400;
`

const FeaturedHeading = styled.h1`
  font-family: ABCGintoNormal-Black;
  font-size: 28px;
`

const Button = styled(RdButton)`
  width: 95%;
  margin-top: 35px;
  margin-bottom: 25px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  line-height: 1;
  letter-spacing: 1.6px;
  min-height: 52px;
  background-color: #8dd4f6;
  border: solid 1px #fff;
  border-radius: 40px;
  padding: 20px 20px;
  color: #fff;
  font-weight: 600;
  font-size: 18px;

  &:hover {
    background-color: #8dd4f6;
    border: solid 1px #8dd4f6;
  }
  &:focus {
    border: solid 1px #8dd4f6;
  }
`

const SmallButton = styled(NoWidthButton)`
   margin-left: 10px;
   margin-bottom: 0px;
   font-size: 15px;
   padding: 2px 7px;
   min-height: 0px;
   font-size: 18px;
   line-height: 29px;
   bottom: 3px;
   position: relative;
`

const Banner = styled.div`
  background-color: #c5a4ec;
  margin: 0 auto 40px auto;
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  cursor: pointer;
`

const TutorialDiv = styled.div`
`

const VideoFrame = styled.iframe`
  min-height: 225px !important;
  min-width: 300px !important;
`

const LandingPage = () => {
  const [featuredProducts, setFeaturedProducts] = useState<Product[]>([]);
  const [allProducts, setAllProducts] = useState<Product[]>([]);
  const { currentUser } = useContext(AuthContext);

  const handleBrowseClick = () => {
    window.location.assign(PRODUCTS_PATH);
  }

  const handleSignUpClick = () => {
    window.location.assign(REGISTER_PATH);
  }

  // const steps = [
  //   {
  //     heading: "Simple 🎁🎉",
  //     description: "Post a celebration and pick your favourite gifts."
  //   },
  //   {
  //     heading: "Social 💝👯",
  //     description: "Share the link to your profile with people you know or add it to your social media."
  //   },
  //   {
  //     heading: "Safe 👷🏻‍♀️🔓",
  //     description: "Receive gifts securely, without sharing your address with anyone."
  //   }
  // ];

  const getAllProducts = async () => {
    try {
      const productsList: Product[] = await ProductService.getProducts();
      setAllProducts(productsList);
    } catch (err) {
      console.error("Error loading Products: ", err)
    }
  };

  useEffect(() => {
    getAllProducts();
  }, []);

  useEffect(() => {
    if (allProducts?.length > 0) {
      const featuredIds: string[] = ["0HEUVuD6PD1fA4sGgB2r", "uk2Ne3GlQqSUOavWoD3n", "c7cIy1xkvxfOiQQVYfI9", "smKS5DoPtBw0ehjs7IgY", "bG9zCeauQoxRJD6hDnN0", "QaQUh5ucLXsfqnQqkSjt", "LkKFJToVIdrxtAEa3row", "jEERo4MOwluQ30oXk496", "0htHBGNx964GOoUJqH6W"]
      setFeaturedProducts(allProducts?.filter(product => featuredIds.includes(product?.id)).sort((a, b) => 0.5 - Math.random()));
    };
  }, [allProducts]);

  return (
    <FooterContainer>
      <ColumnsContainer>
        {!currentUser && <Banner className='margin-ipad-20' onClick={handleSignUpClick} />}
        <TwoColumn>
          <LeftColumn>
            <Heading>Corporate gifting</Heading>
            <Heading>made personal</Heading>
            <Grid container direction="column" justifyContent="center" alignItems="center">
              <TextContent>
                <Heading3 style={{ marginTop: "10px" }} className="padding-24-under-767">Whether you're gifting employees or clients, send the perfect gift to grow your business relationships.</Heading3>
                <Heading3 style={{ paddingTop: "0px" }} className="padding-24-under-767">No address needed to send a gift.</Heading3>
                {currentUser ?
                  <Button onClick={goToEarnSetup}>Send a gift</Button>
                  :
                  <Button onClick={handleSignUpClick}>Sign up</Button>
                }
              </TextContent>
            </Grid>
          </LeftColumn>
          <RightColumn>
            <HeroImg src={Hero} />
          </RightColumn>
        </TwoColumn>
        <MainDiv style={{ marginTop: "20px" }}>
          <TutorialDiv className='center'>
            <FeaturedHeading style={{ marginBottom: "30px" }}>How it works</FeaturedHeading>
            <VideoFrame src="https://www.youtube.com/embed/9Dd1r4yWFnA" className='iframe-size-responsive' />
          </TutorialDiv>
          {!currentUser && <>
            <FeaturedHeading className='center' style={{ marginTop: "90px" }}>
              Featured gifts <SmallButton onClick={handleBrowseClick}>View more</SmallButton>
            </FeaturedHeading>
            <ZeroMarginGrid container alignItems="center" spacing={4} columns={{ xs: 1, sm: 1, md: 12, lg: 12 }} columnSpacing={{ xs: 0.5 }}>
              {featuredProducts?.length > 0 ?
                <>
                  {featuredProducts?.map(product => (
                    <ZeroMarginGrid item xs={1} sm={1} md={6} lg={4} key={product?.id}>
                      <Product product={product} />
                    </ZeroMarginGrid>
                  ))}
                </>
                :
                <Grid container direction="row" justifyContent="space-evenly" alignItems="center">
                  {[0, 1, 2].map(i => (
                    <div key={i}>
                      <Skeleton variant="rounded" width={250} height={300} style={{ margin: "auto" }} />
                      <Skeleton width={250} style={{ margin: "auto" }} />
                    </div>
                  ))}
                </Grid>
              }
            </ZeroMarginGrid></>
          }
        </MainDiv>
      </ColumnsContainer>
      <Footer />
    </FooterContainer>
  );
}

export default LandingPage;
