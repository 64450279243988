import { addDoc, doc, updateDoc, query, where, getDocs, collection,
  setDoc, and, or, deleteDoc, orderBy } from "firebase/firestore";

import { firestoreDb } from "../firebase";
import { CONNECTED, CONNECTIONS, DESC, EQUALS, NOT_IN, PENDING, RECEIVER_ID, REMOVED,
  REQUESTER_ID, REQUESTER_USERNAME, STATUS, UPDATED_AT } from "../constants";
import StorageService from "./storageService";

const getConnectionsByUserId = async (userId: String) : Promise<Connection[]> => {
  try {
    const q = query(collection(firestoreDb, CONNECTIONS), or(
      where(REQUESTER_ID, EQUALS, userId),
      where(RECEIVER_ID, EQUALS, userId))
    );
    const connectionsSnapshot = await getDocs(q);
    if (connectionsSnapshot?.size > 0) {
      return connectionsSnapshot.docs?.map(connection => connection.data() as Connection);
    };
    return null;
  } catch (e) {
    console.error("Error getting connection by userId: ", e);
  }
}

const getLatestConnectionBetweenUsers = async (userA: String, userB: String) : Promise<Connection> => {
  try {
    const q = query(collection(firestoreDb, CONNECTIONS), or(
      and(where(REQUESTER_ID, EQUALS, userA), where(RECEIVER_ID, EQUALS, userB)),
      and(where(REQUESTER_ID, EQUALS, userB), where(RECEIVER_ID, EQUALS, userA))
    ));
    const connectionsSnapshot = await getDocs(q);
    if (connectionsSnapshot?.size > 0) {
      return connectionsSnapshot.docs
        ?.map(connection => connection.data())
        ?.sort((con1, con2) => con2.updatedAt - con1.updatedAt)
        ?.at(0) as Connection;
    };
    return null;
  } catch (e) {
    console.error("Error getting connection between users: ", e);
  };
}

const getAllConnections = async (excludedUsers: string[]) : Promise<Connection[]> => {
  try {
    const q = query(collection(firestoreDb, CONNECTIONS), where(REQUESTER_USERNAME, NOT_IN, excludedUsers));
    const connectionsSnapshot = await getDocs(q);
    return connectionsSnapshot?.docs?.map(doc => doc.data() as Connection).filter(conn => !excludedUsers.includes(conn?.receiver?.username));
  } catch (e) {
    console.error("Error getting all connections: ", e);
  }
}

const getEachAndEveryConnection = async () : Promise<Connection[]> => {
  try {
    const q = query(collection(firestoreDb, CONNECTIONS), orderBy(UPDATED_AT, DESC));
    const connectionsSnapshot = await getDocs(q);
    return connectionsSnapshot?.docs?.map(doc => doc.data() as Connection);
  } catch (e) {
    console.error("Error getting all connections: ", e);
  }
}

const addConnectionReq = async (requester: Account, receiver: Account) : Promise<void> => {
  try {
    const connectionsSnapshot = await getDocs(query(
      collection(firestoreDb, CONNECTIONS),
      where(REQUESTER_ID, EQUALS, requester?.id),
      where(RECEIVER_ID, EQUALS, receiver?.id)
    ));
    let connectionsRef: any;
    if (connectionsSnapshot.size === 0) {
      connectionsRef = await addDoc(collection(firestoreDb, CONNECTIONS), {
        status: PENDING,
        requester: {
          id: requester.id,
          username: requester.username,
          name: requester.name,
          image: StorageService.getImageOrEmpty(requester.images),
          phone: requester?.phone,
          email: requester?.email
        },
        receiver: {
          id: receiver.id,
          username: receiver.username,
          name: receiver.name,
          image: StorageService.getImageOrEmpty(receiver.images),
          phone: receiver?.phone,
          email: receiver?.email
        },
        createdAt: Date.now(),
        updatedAt: Date.now()
      });
      await updateDoc(connectionsRef, { id: connectionsRef.id });
    } else if (connectionsSnapshot.size === 1) {
      connectionsRef = doc(firestoreDb, CONNECTIONS, connectionsSnapshot.docs.at(0)?.id);
      await updateDoc(connectionsRef, {
        status: PENDING,
        updatedAt: Date.now(),
        requester: {
          id: requester.id,
          username: requester.username,
          name: requester.name,
          image: StorageService.getImageOrEmpty(requester.images),
          phone: requester?.phone,
          email: requester?.email
        },
        receiver: {
          id: receiver.id,
          username: receiver.username,
          name: receiver.name,
          image: StorageService.getImageOrEmpty(receiver.images),
          phone: receiver?.phone,
          email: receiver?.email
        }
      });
    }
  } catch (e) {
    console.error("Error adding connection as pending: ", e);
  }
}

const updateConnectionAccepted = async (requesterId: string, receiverId: string) : Promise<void> => {
  try {
    const connectionsSnapshot = await getDocs(
      query(collection(firestoreDb, CONNECTIONS),
        where(REQUESTER_ID, EQUALS, requesterId),
        where(RECEIVER_ID, EQUALS, receiverId),
        where(STATUS, EQUALS, PENDING)
      )
    );
    if (connectionsSnapshot?.size > 0) {
      const connection: Connection = connectionsSnapshot.docs
        ?.map(connection => connection.data())
        ?.sort((con1, con2) => con2.updatedAt - con1.updatedAt)
        ?.at(0) as Connection;
      await updateDoc(doc(firestoreDb, CONNECTIONS, connection.id), {
        ...connection,
        status: CONNECTED,
        updatedAt: Date.now()
      });
    };
  } catch (e) {
    console.error("Error updating connection as accepted: ", e);
  }
}

const updateConnectionRemoved = async (requesterId: string, receiverId: string) : Promise<void> => {
  try {
    const connectionsSnapshot = await getDocs(
      query(collection(firestoreDb, CONNECTIONS),
        where(REQUESTER_ID, EQUALS, requesterId),
        where(RECEIVER_ID, EQUALS, receiverId)
      )
    );
    if (connectionsSnapshot?.size > 0) {
      const connection: Connection = connectionsSnapshot.docs
        ?.map(connection => connection.data())
        ?.sort((con1, con2) => con2.updatedAt - con1.updatedAt)
        ?.at(0) as Connection;
      await updateDoc(doc(firestoreDb, CONNECTIONS, connection.id), {
        ...connection,
        status: REMOVED,
        updatedAt: Date.now()
      });
    }
  } catch (e) {
    console.error("Error updating connection as removed: ", e);
  }
}

const updateUsersDetails = async (connectionToUpdate: Connection) : Promise<void> => {
  try {
    await setDoc(doc(firestoreDb, CONNECTIONS, connectionToUpdate.id),
      { requester: connectionToUpdate.requester, receiver: connectionToUpdate.receiver},
      { merge: true }
    );
  } catch (e) {
    console.error("Error updating connection users: ", e);
  }
}

const canGift = (gifter: Account, giftee: Account | SubUser) : boolean => {
  if (gifter?.id === giftee?.id) {
    return false;
  };
  return true;
};

const deleteConnection = async (connectionId: string) : Promise<void> => {
  try {
    await deleteDoc(doc(firestoreDb, CONNECTIONS, connectionId));
  } catch (e) {
    console.error("Error deleting connection by id: ", e);
  }
}

const ConnectionService = {
  getConnectionsByUserId,
  getLatestConnectionBetweenUsers,
  getAllConnections,
  getEachAndEveryConnection,
  addConnectionReq,
  updateConnectionAccepted,
  updateConnectionRemoved,
  updateUsersDetails,
  canGift,
  deleteConnection
}

export default ConnectionService;
