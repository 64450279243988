import { FC, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Grid as MuiGrid, Card as MuiCard, CardMedia } from '@mui/material';
import moment from 'moment';

import { CardActions, CardContent, CardHeader, ChipImg, MessageBody, Text16Gray, PostButton, ProfileImg40x40Left, TextMargin0,
  Time, Title, Tooltip, Badge as StyledBadge, DotSeparator, Link, CardHeaderGrid } from '../children/styled';
import OfferService from '../../services/offerService';
import { ACCEPTED, COMPLETED, DECLINED, DEFAULT_PROFILE_IMG, EMPTY_STRING, PENDING } from '../../constants';
import UserService from '../../services/userService';
import StorageService from '../../services/storageService';
import { getBadgePoints, updateImageOnError } from '../../services/helpers';

const Card = styled(MuiCard)`
   margin-bottom: 30px;
   padding: 12px 0px 4px 0px;
   width: 100%;
   max-width: 24rem;
   box-shadow: rgba(0, 0, 0, 0.04) 0px 2px 4px;
   border: 1px solid #E2E8F0;
   border-radius: 0.75rem;
   box-sizing: border-box;

   &:hover {
     border-color: #CBD5E0;
   }
`

const DisabledButton = styled(MuiGrid)`
  min-height: 40px;
  align-items: center;
  line-height: 23.8px;
  font-size: 18px;
  display: grid;
  text-align: center;
  color: #65676B;
  font-weight: 500;
  width: 100%;
`

const Comment = styled(MuiGrid)`
  padding: 8px 16px;
`

const CommentImg = styled(MuiGrid)`
  padding-top: 0px;
`

const Badge = styled(StyledBadge)`;
  display: inline;
  font-size: 12px;
  padding: 2px 7px;
  bottom: 0px;
  margin: 0px;
`

const TickIcon = styled.i`
  margin-left: 3px;
  color: #3aab62;
  font-size: 11px;
`

const CommentLink = styled(Link)`
  color: #050505;
  font-size: 14px;
`

interface OfferProps {
  offer: Offer;
  userSent: boolean;
  currentUser: Account;
  setSelectedOffer: (offer: Offer) => void;
  setAddressModalOpen: (val: boolean) => void;
  setOfferForBadge: (offer: Offer) => void;
  setShowBadgeModal: (val: boolean) => void;
};
const Offer: FC<OfferProps> = ({ offer, userSent, currentUser, setAddressModalOpen, setSelectedOffer, setOfferForBadge, setShowBadgeModal }) => {
  const [userIsSenderOrReceiver, setUserIsSenderOrReceiver] = useState<boolean>(false);
  const [actionButtons, setActionButtons] = useState(null);

  const handleDeclineClick = async () => {
    if (currentUser?.id !== offer?.giftee?.id) {
      return;
    };
    const newOffer: Offer = await OfferService.updateOffer({
      ...offer,
      status: DECLINED,
      updatedAt: Date.now()
    })
    setActionButtons(getActionButtons(newOffer));
  };

  const handleAcceptClick = () => {
    if (currentUser?.id !== offer?.giftee?.id) {
      return;
    };
    setSelectedOffer(offer);
    setAddressModalOpen(true);
  };

  const handleBadgeClick = () => {
    setOfferForBadge(offer);
    setShowBadgeModal(true);
  };

  const getActionButtons = (offerObj: Offer) => {
    const status = offerObj?.status;
    if (status === ACCEPTED) {
      return (
        <CardActions>
          <DisabledButton container direction="row" justifyContent="space-around" alignItems="center">You accepted</DisabledButton>
        </CardActions>
      );
    } else if (status === COMPLETED) {
      return (
        <CardActions>
          <MuiGrid container direction="row" justifyContent="space-around" alignItems="center" style={{ padding: "6px 2px 2px 2px" }}>
            <PostButton onClick={handleDeclineClick}>Remove</PostButton>
            <PostButton onClick={handleAcceptClick}>Accept</PostButton>
          </MuiGrid>
        </CardActions>
      );
    } else if (status === DECLINED) {
      return (
        <CardActions>
          <DisabledButton container direction="row" justifyContent="space-around" alignItems="center">Removed</DisabledButton>
        </CardActions>
      );
    }
  };

  const hasMessages = () : boolean => {
    return (offer?.message?.trim() !== EMPTY_STRING) || (offer?.gifteeMessage && offer?.gifteeMessage?.trim() !== EMPTY_STRING);
  };

  const formatMessage = (message: string, index: number, previousMessage: string) => {
    if (message === EMPTY_STRING && previousMessage !== EMPTY_STRING) {
      return (<br key={index} />);
    };
    return (<TextMargin0 style={{ lineHeight: "20px" }} key={index}>{message}</TextMargin0>);
  };

  const getImageIfValid = () => {
    const imageUrl: string = StorageService.getImageOrEmpty([offer?.giftee?.image]);
    if (imageUrl !== EMPTY_STRING && imageUrl !== DEFAULT_PROFILE_IMG) {
      return (
        <ProfileImg40x40Left id="myImg" src={imageUrl} onClick={() => window.location.assign(`/${offer?.giftee?.username}`)}
          onError={() => updateImageOnError(offer)} style={{ float: "none", marginLeft: "8px" }} />
      );
    };
  };

  useEffect(() => {
    if (currentUser?.id === offer?.giftee?.id || currentUser?.id === offer?.gifter?.id) {
      setUserIsSenderOrReceiver(true);
      if (!userSent) {
        setActionButtons(getActionButtons(offer));
      };
    };
  }, [offer]);

  return (
    <Card className='mobile-card center-width-24rem'>
      <CardHeader
        avatar={<ProfileImg40x40Left id="myImg" src={StorageService.getImageOrDefault([offer?.gifter.image])} onError={() => updateImageOnError(offer)}
          onClick={() => window.location.assign(`/${offer?.gifter?.username}`)} />}
        title={
          <CardHeaderGrid container direction="row" justifyContent="flex-start" alignItems="center">
            <Text16Gray onClick={() => window.location.assign(`/${offer?.gifter?.username}`)} style={{ marginTop: "0px" }}>
              {UserService.getNameOrUsername(offer?.gifter?.name, offer?.gifter?.username)}
            </Text16Gray>
            <Tooltip title={moment(offer?.createdAt).format('ll')} enterDelay={2000} enterNextDelay={1000} placement='right'>
              <Time>
                <DotSeparator style={{ top: "1px" }}>•</DotSeparator>
                <span style={{ top: "2px", position: "relative" }}>{moment(offer?.createdAt).fromNow(true)}</span>
              </Time>
            </Tooltip>
          </CardHeaderGrid>
        }
        subheader={<>
          <Badge onClick={handleBadgeClick}>
            +{getBadgePoints(offer?.product?.price?.amount, offer?.checkout?.shippingFee, offer?.checkout?.processingFee)} points
          </Badge>
          {userIsSenderOrReceiver && userSent && (offer?.status === ACCEPTED || offer?.status === COMPLETED) && <>
            <Time style={{ display: "inline-block" }}>
              <DotSeparator style={{ bottom: "1px" }}>•</DotSeparator>
              {offer?.status === ACCEPTED && <>
                Accepted
                <TickIcon className="fa-regular fa-circle-check" /></>
              }
              {offer?.status === COMPLETED && <>Sent</>}
            </Time>
          </>}
        </>}
      />
      <CardContent>
        <TextMargin0>
          { !userIsSenderOrReceiver && <>
            <span style={{ marginRight: "3px" }}>🎁</span> Sent a gift to
            <Link onClick={() => window.location.assign(`/${offer?.giftee?.username}`)} style={{ marginLeft: "4px" }}>
              {UserService.getNameOrUsername(offer?.giftee?.name, offer?.giftee?.username)}
            </Link>
            {getImageIfValid()}</>
          }
          { userIsSenderOrReceiver && !userSent &&
            <><span style={{ marginRight: "3px" }}>🎁</span> Sent a gift to you!</>
          }
          { userIsSenderOrReceiver && userSent && <>
            <span style={{ marginRight: "3px" }}>🎁</span> You sent a gift to
            <Link onClick={() => window.location.assign(`/${offer?.giftee?.username}`)} style={{ marginLeft: "4px" }}>
              {UserService.getNameOrUsername(offer?.giftee?.name, offer?.giftee?.username)}
            </Link>
            {getImageIfValid()}</>
          }
        </TextMargin0>
      </CardContent>
      <CardMedia onClick={() => window.open(offer?.product?.url)} component="img" height="194" image={offer?.product?.images?.at(0)}
        style={{ cursor: "pointer", objectFit: "contain", height: "fit-content", maxHeight: "600px" }} className='min-height-mobile' />
      <Title onClick={() => window.open(offer?.product?.url)} style={{ textAlign: "center", cursor: "pointer", padding: "0px 8px" }}>{offer?.product?.title}</Title>
      { userIsSenderOrReceiver && !userSent && <>{actionButtons}</> }
      { hasMessages() && <hr style={{ margin: "0px auto 10px auto" }}/> }
      <>{offer?.message?.trim() !== EMPTY_STRING &&
        <Comment container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={0.5}>
          <CommentImg item>
            <ChipImg onClick={() => window.location.assign(`/${offer?.gifter?.username}`)} onError={() => updateImageOnError(offer)}
              src={StorageService.getImageOrDefault([offer?.gifter?.image])} style={{ height: "32px", width: "32px", margin: "0px", cursor: "pointer" }} />
          </CommentImg>
          <MessageBody item style={{ width: "auto", maxWidth: "85%", marginLeft: "5px" }} className='width-80-max-397'>
            <CommentLink onClick={() => window.location.assign(`/${offer?.gifter?.username}`)}>
              {UserService.getNameOrUsername(offer?.gifter?.name, offer?.gifter?.username)}
            </CommentLink>
            {offer?.message?.split(/\r?\n/)?.map((text, i, arr) => (
              formatMessage(text, i, arr[i-1])
            ))}
          </MessageBody>
        </Comment>
      }</>
      <>{offer?.gifteeMessage && offer?.gifteeMessage?.trim() !== EMPTY_STRING &&
        <Comment container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={0.5}>
          <CommentImg item>
            <ChipImg onClick={() => window.location.assign(`/${offer?.giftee?.username}`)} onError={() => updateImageOnError(offer)}
              src={StorageService.getImageOrDefault([offer?.giftee?.image])} style={{ height: "32px", width: "32px", margin: "0px", cursor: "pointer" }} />
          </CommentImg>
          <MessageBody item style={{ width: "auto", maxWidth: "85%", marginLeft: "5px" }} className='width-80-max-397'>
            <CommentLink onClick={() => window.location.assign(`/${offer?.giftee?.username}`)}>
              {UserService.getNameOrUsername(offer?.giftee?.name, offer?.giftee?.username)}
            </CommentLink>
            {offer?.gifteeMessage?.split(/\r?\n/)?.map((text, i, arr) => (
              formatMessage(text, i, arr[i-1])
            ))}
          </MessageBody>
        </Comment>
      }</>
    </Card>
  );
};

export default Offer;
