import { FC, useEffect, useState } from 'react';
import { useStripe } from '@stripe/react-stripe-js';

import { COMPLETED, EMPTY_STRING, PROCESSING, REQUIRES_PAYMENT_METHOD, SUCCEEDED, SUCCESS } from '../../constants';
import OfferService from '../../services/offerService';
import StripeService from '../../services/stripeService';
import { Button, MainDiv, PageHeading, Text } from './styled';
import { goToHomeOnClick } from '../../services/helpers';
import BadgeService from '../../services/badgeService';
import { emptyBadge } from '../../services/emptyObjs';
import StorageService from '../../services/storageService';

interface PaymentStatusProps {
  clientSecret: string;
}
const PaymentStatus: FC<PaymentStatusProps> = ({ clientSecret }) => {
  const [title, setTitle] = useState<string>(EMPTY_STRING);
  const [status, setStatus] = useState<string>(EMPTY_STRING);

  const stripe = useStripe();

  const getPaymentStatus = async () => {
    if (!stripe) {
      return;
    };
    const { paymentIntent } = await stripe.retrievePaymentIntent(clientSecret);
    switch (paymentIntent.status) {
      case SUCCEEDED:
        setTitle("Payment successful! 🎉");
        setStatus(SUCCESS);
        const paymentId: string = await StripeService.getPaymentIdByIntentId(paymentIntent.id);
        const offerObj: Offer = await OfferService.getOfferByPaymentId(paymentId);
        await OfferService.updateOffer({
          ...offerObj,
          status: COMPLETED,
          updatedAt: Date.now()
        });
        const gifter: SubUser = offerObj?.gifter;
        const gifterBadge: Badge = await BadgeService.getUserBadge(gifter?.id);
        const pointsIncrease: number = BadgeService.getPointsIncrease(paymentIntent.amount);
        if (gifterBadge) {
          await BadgeService.updateBadge({
            ...gifterBadge,
            points: gifterBadge?.points + pointsIncrease,
            updatedAt: Date.now()
          });
        } else {
          await BadgeService.addBadge({...emptyBadge,
            updatedAt: Date.now(),
            points: pointsIncrease,
            account: {
              id: gifter?.id,
              username: gifter?.username,
              name: gifter?.name,
              email: gifter?.email,
              image: StorageService.getImageOrEmpty([gifter?.image]),
              phone: gifter?.phone
            }
          });
        };
        break;
      case PROCESSING:
        setTitle("Your payment is being processed. We'll update you when the payment is received.");
        break;
      case REQUIRES_PAYMENT_METHOD:
        setTitle('Payment failed. Please try again with another payment method.');
        break;
      default:
        setTitle('Something went wrong. Please try again later.');
        break;
    }
  };

  useEffect(() => {
    getPaymentStatus();
  }, [stripe]);

  return (
    <MainDiv>
      <PageHeading className='center-width-24rem'>{title}</PageHeading>
      { status === SUCCESS &&
        <Text className='center-width-24rem' style={{ marginBottom: "20px" }}>
          Your gift offer has been sent! You will be refunded in full if it is not accepted.
        </Text>
      }
      <div className='center-width-24rem'>
        <Button onClick={goToHomeOnClick}>Home</Button>
      </div>
    </MainDiv>
  );
};

export default PaymentStatus;
